import {
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor, HttpProgressEvent,
  HttpRequest, HttpResponse,
  HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableInput } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { GeneralErrorHandlerService } from '../service/general-error-handler.service';
import * as Sentry from '@sentry/angular';


export type CaughtType = Observable<HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>>;

export class HandlableError {
  public handled = false;

  constructor(
    public error: any,
    public caught: CaughtType,
  ) {
  }

  markAsHandled() {
    this.handled = true;
  }
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private generalErrorHandlerService: GeneralErrorHandlerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handlableError: HandlableError = null;

    return next.handle(req).pipe(
      catchError((err: any, caught: CaughtType): ObservableInput<any> => {
        Sentry.captureException(err);
        throw handlableError = new HandlableError(err, caught);
      }),
      finalize(() => {
        if (handlableError && !handlableError.handled) {
          this.generalErrorHandlerService.handleNotHandledError(handlableError.error);
        }
      })
    )
  }
}
